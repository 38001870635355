import React from 'react';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const NotFoundPage = () => (
  <>
    <SEO title="Page Not Found" />
    <div className="common-page">
      <HeadingSection h1="Page Not Found" image={imageIllustrationPrivacyPolicy} />
      {/* <h2>Sorry, but the page you were trying to view does not exist.</h2> */}
    </div>
  </>
);

export default NotFoundPage;
